body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'VT323';
  src: url('./fonts/VT323-Regular.ttf'); /* IE9 Compat Modes */
  src: url('./fonts/VT323-Regular.ttf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/VT323-Regular.ttf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/VT323-Regular.ttf') format('woff'), /* Pretty Modern Browsers */
       url('./fonts/VT323-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/VT323-Regular.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Mukta';
  src: url('./fonts/Mukta-Regular.ttf'); /* IE9 Compat Modes */
  src: url('./fonts/Mukta-Regular.ttf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Mukta-Regular.ttf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Mukta-Regular.ttf') format('woff'), /* Pretty Modern Browsers */
       url('./fonts/Mukta-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Mukta-Regular.ttf') format('svg'); /* Legacy iOS */
}
