:root {
  --drop-height: 200px;
  --offset: 100px;
  --shadow-height: 8px;
  --duration: 0.5s;
}

.App {
  text-align: center;
  height: 100vh;
  font-family: 'Mukta';
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #B37283;
}

.lilpep {
  height: 10vmin;
  position:fixed;
  left:50%;
  bottom:0;
  margin-top:-25px;
  margin-left:-25px;
  animation: bounce 1s infinite;
  -webkit-animation: bounce 1s infinite;
}

@keyframes bounce {
  0%       { bottom: 5px; }
  25%, 75% { bottom: 15px; }
  50%      { bottom: 20px; }
  100%     { bottom: 0;}
}
